import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { removeToken } from "../../store/slice/AuthSlice";
import Button from "../Button/Button";
import { getSuper } from "../../store/slice/SuperSclice";
import { defaultAxios } from "../../defaultAxios";
export default function Header() {
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const dropdownRef2 = useRef(null);
  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };
  const handleClickOutside2 = (event) => {
    if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
      setIsDropdownOpen2(false);
    }
  };
  const token = useSelector((state) => {
    return state.auth.token
  })
  const check = async () => {
    setInterval(async () => {
      if (token) {

        const get = await dispatch(getSuper("order=active"))
        if (get.payload.message === 'Token Expired!!') dispatch(removeToken())
      }
    }, 10 * 60 * 1000)
  }
  const dispatch = useDispatch()
  useEffect(() => {

    defaultAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
    check()
  }, [])
  const navigate = useNavigate()
  if (!token)
    return navigate('/login')
  return (
    <header
      className="fixed w-full z-50"
      style={{ boxShadow: "0px 2px 16px 0px rgba(0, 22, 61, 0.08)" }}
    >
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-5">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-[1296px] w-full">
          <div>
            <Link to="/" className="flex items-center justify-start">
              <span className="self-center text-xl font-semibold whitespace-nowrap ">
                <img src="https://cdn.searchmyexpert.com/sme_logo_dark.svg" className="md:w-auto w-[160px]" />
              </span>
            </Link>
          </div>
          {token && <div className="flex items-center justify-end relative">
            <button onClick={toggleDropdown2} className="flex gap-2 items-center md:text-base text-sm">
              <b>Hi Admin!</b>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M13.9987 2.33203C12.4666 2.33203 10.9495 2.6338 9.53406 3.2201C8.11859 3.80641 6.83247 4.66577 5.74912 5.74912C3.56119 7.93704 2.33203 10.9045 2.33203 13.9987C2.33203 17.0929 3.56119 20.0604 5.74912 22.2483C6.83247 23.3316 8.11859 24.191 9.53406 24.7773C10.9495 25.3636 12.4666 25.6654 13.9987 25.6654C17.0929 25.6654 20.0604 24.4362 22.2483 22.2483C24.4362 20.0604 25.6654 17.0929 25.6654 13.9987C25.6654 12.4666 25.3636 10.9495 24.7773 9.53406C24.191 8.11859 23.3316 6.83247 22.2483 5.74912C21.1649 4.66577 19.8788 3.80641 18.4633 3.2201C17.0479 2.6338 15.5308 2.33203 13.9987 2.33203ZM8.24703 21.3254C8.7487 20.2754 11.8054 19.2487 13.9987 19.2487C16.192 19.2487 19.2487 20.2754 19.7504 21.3254C18.1637 22.5854 16.1687 23.332 13.9987 23.332C11.8287 23.332 9.8337 22.5854 8.24703 21.3254ZM21.4187 19.6337C19.7504 17.6037 15.702 16.9154 13.9987 16.9154C12.2954 16.9154 8.24703 17.6037 6.5787 19.6337C5.3887 18.082 4.66536 16.122 4.66536 13.9987C4.66536 8.8537 8.8537 4.66536 13.9987 4.66536C19.1437 4.66536 23.332 8.8537 23.332 13.9987C23.332 16.122 22.6087 18.082 21.4187 19.6337ZM13.9987 6.9987C11.7354 6.9987 9.91536 8.8187 9.91536 11.082C9.91536 13.3454 11.7354 15.1654 13.9987 15.1654C16.262 15.1654 18.082 13.3454 18.082 11.082C18.082 8.8187 16.262 6.9987 13.9987 6.9987ZM13.9987 12.832C13.5346 12.832 13.0894 12.6477 12.7613 12.3195C12.4331 11.9913 12.2487 11.5462 12.2487 11.082C12.2487 10.6179 12.4331 10.1728 12.7613 9.84459C13.0894 9.51641 13.5346 9.33203 13.9987 9.33203C14.4628 9.33203 14.9079 9.51641 15.2361 9.84459C15.5643 10.1728 15.7487 10.6179 15.7487 11.082C15.7487 11.5462 15.5643 11.9913 15.2361 12.3195C14.9079 12.6477 14.4628 12.832 13.9987 12.832Z"
                  fill="#60666F"
                  stroke="white"
                  stroke-width="0.4"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.4226 19.6357C19.7543 17.6057 15.7059 16.9173 14.0026 16.9173C12.2993 16.9173 8.25094 17.6057 6.5826 19.6357C5.3926 18.084 4.66927 16.124 4.66927 14.0007C4.66927 8.85565 8.8576 4.66732 14.0026 4.66732C19.1476 4.66732 23.3359 8.85565 23.3359 14.0007C23.3359 16.124 22.6126 18.084 21.4226 19.6357ZM9.91927 11.084C9.91927 8.82065 11.7393 7.00065 14.0026 7.00065C16.2659 7.00065 18.0859 8.82065 18.0859 11.084C18.0859 13.3473 16.2659 15.1673 14.0026 15.1673C11.7393 15.1673 9.91927 13.3473 9.91927 11.084Z"
                  fill="#E8E8E8"
                />
              </svg>
            </button>
            {isDropdownOpen2 && (
              <ul className="z-10 w-[120px] absolute top-0 right-0 mt-7 bg-white border rounded-md shadow-md">
                <li>
                  <Button
                    onClick={() => dispatch(removeToken())}
                    className="flex w-full items-center gap-4 px-3 py-2 rounded-md text-[#323232] hover:bg-gray-200"
                  >
                    <span className="grow-0">Logout</span>

                  </Button>
                </li>
              </ul>
            )}
          </div>}
        </div>
      </nav>
    </header>
  );
}
