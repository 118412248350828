import { configureStore } from '@reduxjs/toolkit'
import storageSession from 'redux-persist/lib/storage/session'
import contactList from './slice/contactSlice'
import TabSlice from './slice/TabSlice'
import AuthSlice from './slice/AuthSlice'
import {
  persistStore, persistReducer, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import SuperSlice from './slice/SuperSclice';
import CategorySlice from './slice/CategorySlice';
import SubSlice from './slice/SubSlice';
import TopicSlice from './slice/TopicSlice'
import InfoSlice from './slice/InfoSlice'
import FAQSlice from './slice/FAQSlice'

const persistConfig = {
  key: 'auth',
  storage: storageSession,
}

const persistedReducer = persistReducer(persistConfig, AuthSlice)
export const store = configureStore({
  reducer: { app: contactList, tabs: TabSlice, auth: persistedReducer, Super: SuperSlice, cat: CategorySlice, sub: SubSlice, topic: TopicSlice, info: InfoSlice, faq: FAQSlice },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})
export const persistor = persistStore(store)