import React, { useEffect, useState } from 'react';

function UploadImage({ onImageChange, url }) {
  const [imageSrc, setImageSrc] = useState(url);
  const inputId = `fileInput_${Math.random().toString(36).substr(2, 9)}`; // Generate unique input ID
  useEffect(() => {
    setImageSrc(url)
  }, [url])
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const imageSrc = event.target.result;
      setImageSrc(imageSrc);
      onImageChange(file); // Pass the image source to the parent component
    };

    reader.readAsDataURL(file);
  };

  const openFile = () => {
    document.getElementById(inputId).click();
  };

  return (
    <div>
      <div
        id="imagePlaceholder"
        onClick={openFile}
      >
        {imageSrc ? (
          <img
            id="imagePreview"
            src={imageSrc}
            alt="Uploaded"
            className='w-[70px] h-[70px] object-cover rounded'
          />
        ) : (
          <span><img alt='default' src='/upload.png' className='w-[70px] h-[70px] object-cover rounded' /></span>
        )}
      </div>
      <input
        type="file"
        id={inputId}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleFileChange}
      />
    </div>
  );
}

export default UploadImage;
