import React from "react";

export default function Button({className,children,onClick}) {
    const combinedClassName = `border-0 font-semibold cursor-pointer rounded-full font-SF_Pro_Display shadow-button-shadow min-h-[42px] relative overflow-hidden flex justify-center items-center ${className} `;
  return (
    <button
      className={combinedClassName}
      onClick={onClick}
    >
      {children}
    </button>
  );
}