import { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { createSuper, deleteSuper, getSuper, updateSuper } from "../../store/slice/SuperSclice";
import { defaultAxios } from "../../defaultAxios";
import SuperModal from "../Modal/Screens/SuperModal";
import DeleteModal from "../Modal/Screens/DeleteModal";
import { getCat } from "../../store/slice/CategorySlice";
import { getSub } from "../../store/slice/SubSlice";
import { getTopic } from "../../store/slice/TopicSlice";
import { reSetTries, setTries } from "../../store/slice/AuthSlice";
import { NormalInput } from "../FormElements/NormalInput";
import { Link } from "react-router-dom";

export default function Table() {
  const [modal, setModal] = useState('')
  const [search, setSearch] = useState('')
  const [index, setIndex] = useState(-1)
  const [state, Error, cats] = useSelector((state) => [state.Super.superCat, state.Super.error, state.cat.Category])
  const dispatch = useDispatch()
  useEffect(() => {
    defaultAxios.defaults.headers.common["ngrok-skip-browser-warning"] = "true";
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    if (state.length === 0) dispatch(getSuper('order=active'))
  }, [])
  const [isDropdownOpen, setIsDropdownOpen] = useState(-1);
  const [orderby, setOrderBy] = useState(false)
  const toggleDropdown = (index) => {
    if (index === isDropdownOpen) setIsDropdownOpen(-1)
    else setIsDropdownOpen(index);
  };
  return (
    <>
      <div className="flex justify-between">
        {/* Category drop down and Search */}
        <div className="flex gap-4">
          {/* <p>DropDown</p> */}
          <div><NormalInput className="h-full placeholder:text-[#86949F] placeholder:text-sm bg-transparent !rounded-md relative font-medium font-SF_Pro_Display text-sm"
            mainClassDiv="w-full sticky h-full top-[-4px] mx-auto bg-white px-[10px]"
            mainClass="!w-[100%] !h-[100%] mx-auto"
            value={search}
            change={(e) => setSearch(e.target.value)}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_4205_78897)">
                  <path
                    d="M13.6299 12.3235L10.577 9.25937C11.4089 8.23814 11.818 6.93504 11.7195 5.61961C11.621 4.30417 11.0226 3.07705 10.0479 2.19206C9.07325 1.30707 7.79697 0.831917 6.48305 0.864889C5.16912 0.897862 3.91809 1.43643 2.98872 2.36921C2.05935 3.30198 1.52274 4.55759 1.48988 5.87631C1.45703 7.19504 1.93045 8.476 2.81222 9.45422C3.69398 10.4324 4.91664 11.0331 6.22727 11.1319C7.53791 11.2308 8.83626 10.8202 9.85377 9.98522L12.9067 13.0493C13.0031 13.1428 13.1323 13.1946 13.2665 13.1934C13.4006 13.1922 13.5288 13.1382 13.6237 13.0431C13.7185 12.9479 13.7723 12.8191 13.7735 12.6845C13.7746 12.5499 13.7231 12.4203 13.6299 12.3235ZM6.61928 10.1197C5.81002 10.1197 5.01893 9.87886 4.34605 9.42761C3.67317 8.97637 3.14873 8.33499 2.83904 7.5846C2.52935 6.8342 2.44832 6.00849 2.6062 5.21187C2.76408 4.41526 3.15377 3.68352 3.72601 3.10919C4.29824 2.53487 5.02731 2.14374 5.82103 1.98529C6.61474 1.82683 7.43744 1.90816 8.1851 2.21898C8.93276 2.5298 9.5718 3.05617 10.0214 3.7315C10.471 4.40684 10.711 5.20082 10.711 6.01305C10.7098 7.10183 10.2783 8.14566 9.5112 8.91555C8.74412 9.68543 7.70409 10.1185 6.61928 10.1197Z"
                    fill="#6E757B"
                    stroke="#6E757B"
                    stroke-width="0.5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4205_78897">
                    <rect
                      width="13.1519"
                      height="13.2"
                      fill="white"
                      transform="translate(0.628906)"
                    />
                  </clipPath>
                </defs>
              </svg>
            } placeholder="Enter super name" /></div>
        </div>
        <Link to={"/add/super-category"}>
          <Button onClick={() => setModal('create')} className="bg-[#1057CB] w-[208px] h-[42px] text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M16.3307 10.8346H11.3307V15.8346H9.66406V10.8346H4.66406V9.16797H9.66406V4.16797H11.3307V9.16797H16.3307V10.8346Z"
                fill="white"
              />
            </svg>
            <span className="hidden md:inline-block mr-1">Add</span> Super-Category
          </Button>
        </Link>


      </div>
      <div className="w-full min-h-[200px] mt-8 overflow-x-auto tab:overflow-visible">
        <table className="tab:w-full w-[1024px]">
          <thead>
            <tr className="text-[#7F8BA5] text-sm uppercase text-left border-b border-[#EEEEEE]">
              <th className="pb-5">S.NO.</th>
              <th className="pb-5">SUPER-CATEGORY</th>
              <th className="pb-5">Category ({cats.length})</th>
              <th onClick={() => {
                dispatch(getSuper(orderby ? "order=active" : "order=inActive"))
                setOrderBy(!orderby)
              }} className="pb-5 flex gap-2 items-center cursor-pointer">STATUS
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.4375 14.3574L8.5625 8.85742H0.3125L4.4375 14.3574Z" fill="#7F8BA5" />
                  <path d="M8.5625 6.10742H0.3125L4.4375 0.607422L8.5625 6.10742Z" fill="#7F8BA5" />
                </svg>
              </th>
              <th className="pb-5 text-right">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {state?.filter(item => {
              return item.name?.toLowerCase()?.startsWith(search.toLowerCase())
            })?.map((item, index) => {
              return <tr key={item.name + item.active} className="border-b border-[#EEEEEE] text-[#323232]">
                <td className="py-5 font-semibold">{index + 1}.</td>
                <td className="py-5 font-semibold">{item.name}</td>
                <td className="py-5">
                  <div className="flex gap-2 max-w-[400px] w-full flex-wrap">
                    {item?.category?.map((cat) => {
                      return <span key={cat.id + cat.name} className="bg-[#EFF2F5] text-[#022D72] font-semibold rounded-md px-3 py-1 text-sm">
                        {cat.name}
                      </span>
                    })}
                  </div>
                </td>
                <td className="py-5">
                  {item.active ? <div className="bg-[#CBFFE7] text-[#018D4C] w-[96px] h-[30px] flex justify-center items-center text-sm font-semibold rounded-lg">
                    Active
                  </div> :
                    <div className="bg-[#FFE5EFB2] text-[#ED1442] w-[96px] h-[30px] flex justify-center items-center text-sm font-semibold rounded-lg">
                      Inactive
                    </div>}
                </td>
                <td className="py-5">
                  <div className="relative">
                    <button className="bg-[#EAF4FF] text-[#1057CB] w-[96px] h-[30px] flex justify-center items-center text-sm font-semibold rounded-lg ml-auto mr-0" onClick={() => toggleDropdown(index)} >
                      Actions
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={isDropdownOpen === index && "rotate-180"}>
                        <path d="M4.94 5.7207L8 8.7807L11.06 5.7207L12 6.66737L8 10.6674L4 6.66737L4.94 5.7207Z" fill="#1057CB" />
                      </svg>
                    </button>
                    {isDropdownOpen === index && (
                      <ul className="z-10 w-[120px] absolute top-0 right-0 mt-7 bg-white border rounded-md shadow-md">
                        <li className="border-b border-[#F2F2F2]">
                          <Link to={"/edit/super-category/" + item.id}>
                            <button
                              onClick={() => {
                                setIndex(item)
                                setModal('edit')
                                setIsDropdownOpen(-1)

                              }}
                              className="flex items-center gap-4 px-3 py-2 rounded-md text-[#323232] hover:bg-gray-200 w-full"
                            >
                              <span className="grow-0 text-[#606060]">Edit</span>

                            </button>
                          </Link>
                        </li>
                        <li>
                          <button
                            onClick={() => {
                              setIndex(item)
                              setModal('delete')
                              setIsDropdownOpen(-1)

                            }}
                            className="flex w-full items-center gap-4 px-3 py-2 rounded-md text-[#323232] hover:bg-gray-200"
                          >
                            <span className="grow-0 text-[#ED1C24]">Delete</span>
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>

      {modal === 'delete' && <Modal onClose={() => setModal('')}>
        <DeleteModal onClose={() => {
          setModal('')
          setIndex(-1)
        }
        }
          data={index}
          deleteItem={async (data) => {
            try {
              const del = await dispatch(deleteSuper(data))
              console.log(del.payload);
              if (del.payload?.type !== 'success') {
                dispatch(setTries())
                return
              }
              dispatch(reSetTries())
              setModal('')
              await dispatch(getSuper('order=active'))
              await dispatch(getCat('order=active'))
              await dispatch(getSub('order=active'))
              await dispatch(getTopic('order=active'))
            }
            catch (e) {
              console.log(e);
            }
          }}
          error={Error}
        />
      </Modal>
      }
    </>

  );
}
