import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, removeToken, setToken } from "../../store/slice/AuthSlice";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const token = useSelector((state) => state.auth.token);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if (token) return navigate("/");
  }, [token]);
  const dispatch = useDispatch();
  const loginFn = async () => {
    if (username === "" || password === "") {
      alert("all fields are required");
    }
    await dispatch(
      login({ username, password, email: username, type: "dashboard" })
    );
  };
  return (
    <div>
      <section className="bg-[#F7FBFF] h-[80vh] tab:pt-[150px] md:pt-[100px] pb-[40px] pt-[40px] md:pb-[100px] tab:pb-[150px] bg-cover bg-no-repeat bg-bottom px-5">
        <div
          className="max-w-[640px] w-full bg-white rounded-2xl pt-9 pb-7 px-4 mx-auto"
          style={{
            boxShadow:
              "0px 0px 20px 0px rgba(46, 79, 142, 0.08), 0px 2px 4px 0px rgba(36, 84, 155, 0.10)",
          }}
        >
          <div className="max-w-[450px] w-full mx-auto text-center">
            <h1 className="text-[#222] text-2xl md:text-3xl font-bold capitalize font-Gordita">
              Sign In
            </h1>

            <form className="py-5">
              <input
                className="border rounded-sm focus:outline-none py-2 px-2 w-full"
                onChange={(e) => setUsername(e.target.value)}
                autoFocus
                placeholder="User Name"
              />
              <input
                className="border rounded-sm focus:outline-none py-2 px-2 w-full mt-5"
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
              />
            </form>

            <button
              className="bg-[#2E78F2] w-full text-2xl !rounded-md text-white h-[56px] capitalize flex justify-center items-center relative"
              onClick={loginFn}
            >
              {" "}
              Login
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
