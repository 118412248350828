import {
    createSlice,
    createAsyncThunk
} from "@reduxjs/toolkit";
import { defaultAxios } from "../../defaultAxios";
import { removeToken } from "./AuthSlice";

const INIT_STATE = {
    Category: [],
    isLoading: false,
    error: null,
    searchData: ""
};

// For creating new contact -> AsynThunk is used for API

export const createCat = createAsyncThunk(
    "createCat",
    async (data, { rejectWithValue, dispatch }) => {
        //we are fetching Api to POST the detail
        try {
            console.log(data);
            const response = await defaultAxios.post(
                "services/createCategory",
                data.formData,

            );
            return response.data;
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
                dispatch(removeToken())
                return
            }
            const e = {
                message: error?.response?.data?.message ?? "something went wrong",
                type: "cat",
                subType: "create"
            }
            return rejectWithValue(e);
        }
    }
);

// For reading All the contact list from API - AsyncThunk is used For API

export const getCat = createAsyncThunk("getCat", async (data, { rejectWithValue, dispatch }) => {
    // here we will fetching the API
    try {
        const response = await defaultAxios.get("services/allCategory?" + data,
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'realtime': true
                }
            }
        );
        return response.data;
    } catch (error) {

        console.log(error);
        if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
            dispatch(removeToken())
            return
        }
        const e = {
            message: error?.response?.data?.message ?? "something went wrong",
            type: "cat",
            subType: "get"

        }
        return rejectWithValue(e);
    }
});

// For updaing contact details of single user from API - AsyncThunk is used For API
export const updateCat = createAsyncThunk("updateCat", async (data, { rejectWithValue, dispatch }) => {
    // here we will fetching the API
    try {

        const response = await defaultAxios.patch(
            `services/updateCategory?id=${data.id}`,
            data.formData,

        );
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
            dispatch(removeToken())
            return
        }
        console.log(error);
        const e = {
            message: error?.response?.data?.message ?? "something went wrong",
            type: "cat",
            subType: "update"

        }
        return rejectWithValue(e);
    }
});

// For Deleting contact details of single user from API - AsyncThunk is used For API
export const deleteCat = createAsyncThunk("deleteCat", async (data, { rejectWithValue, dispatch }) => {
    // here we will fetching the API
    try {
        const response = await defaultAxios.delete(
            `services/deleteCategory?id=${data.id}&p=${data.password}`

        );
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
            dispatch(removeToken())
            return
        }
        console.log(error);
        const e = {
            message: error?.response?.data?.message ?? "something went wrong",
            type: "cat",
            subType: "delete"

        }
        return rejectWithValue(e);
    }
});

export const CategorySlice = createSlice({
    name: "CategorySlice",
    initialState: INIT_STATE,
    reducers: {
        setSearch: (state, action) => {
            console.log(action);
            state.searchData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCat.pending, (state) => {
                state.isLoading = true;
                state.error = ""
            })
            .addCase(createCat.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = ""
                state.Category.push(action.payload);
            })
            .addCase(createCat.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getCat.pending, (state) => {
                state.isLoading = true;
                state.error = ""
            })
            .addCase(getCat.fulfilled, (state, action) => {
                state.isLoading = false;
                state.Category = action.payload;
            })
            .addCase(getCat.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            }).addCase(updateCat.pending, (state) => {
                state.isLoading = true;
                state.error = ""
            })
            .addCase(updateCat.fulfilled, (state, action) => {
                state.isLoading = false;
                state.Category = state.Category.map((details) => details.id === action.payload.id ? action.payload : details)
            })
            .addCase(updateCat.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteCat.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteCat.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = ""
                if (action.payload.id) {
                    state.Category = state.Category.filter((item) => item.id !== action.payload.id)
                }

            })
            .addCase(deleteCat.rejected, (state, action) => {
                state.isLoading = false;
                console.log(action);
                state.error = action.payload;
            });

    },
});
export default CategorySlice.reducer;
export const { steActive } = CategorySlice.actions;