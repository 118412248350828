export function NormalInput({
    className = "",
    placeholder,
    mainClassDiv,
    type,
    change,
    name,
    label,
    error,
    icon,
    value = "",
    required,
    mainClass = "",
}) {
    return (
        <>
            <div className={mainClassDiv}>
                {label ? ( // showing label only if passed as a prop
                    <label className="text-left">
                        <p className="pb-2">
                            {label} <span className="text-red-500 ">{required ? " *" : ""}</span>
                        </p>
                    </label>
                ) : null}
                <div
                    className={`appearance-none pr-3 bg-white border flex items-center border-[#DCDCDC] hover:border-primary-blue transition-all rounded-lg w-full  text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-SF_Pro_Display text-[15px] ${mainClass}`}
                >
                    <input
                        placeholder={placeholder}
                        className={`w-full px-3 ${className}`}
                        autoComplete="off"
                        type={type}
                        onChange={change}
                        value={value}
                        required
                    />
                    {icon ? icon : null}
                </div>
                <p className="text-red-500 text-sm px-3 text-left">{error?.message}</p>
            </div>
        </>
    );
}