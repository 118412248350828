import {
    createSlice,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import { defaultAxios } from "../../defaultAxios";
import { removeToken } from "./AuthSlice";

const INIT_STATE = {
    superCat: [],
    isLoading: false,
    error: null,
    searchData: ""
};

// For creating new contact -> AsynThunk is used for API

export const createSuper = createAsyncThunk(
    "createSuper",
    async (data, { rejectWithValue, dispatch }) => {
        //we are fetching Api to POST the detail
        try {
            const response = await defaultAxios.post(
                "services/createSuperCategory",
                data,

            );
            return { ...response.data, ...data };
        } catch (error) {
            if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
                dispatch(removeToken())
                return
            }
            console.log(error);
            const e = {
                message: error?.response?.data?.message ?? "something went wrong",
                type: "super",
                subType: "create"
            }
            return rejectWithValue(e);
        }
    }
);

// For reading All the contact list from API - AsyncThunk is used For API

export const getSuper = createAsyncThunk("getSuper", async (data, { rejectWithValue, dispatch }) => {
    // here we will fetching the API
    try {
        const response = await defaultAxios.get("services/allSuperCategory?" + data, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                realtime: true
            }
        });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
            dispatch(removeToken())
            return
        }
        console.log(error);
        const e = {
            message: error?.response?.data?.message ?? "something went wrong",
            type: "super",
            subType: "get"
        }
        return rejectWithValue(e);
    }
});

// For updaing contact details of single user from API - AsyncThunk is used For API
export const updateSuper = createAsyncThunk("updateSuper", async (data, { rejectWithValue, dispatch }) => {
    // here we will fetching the API
    try {

        const response = await defaultAxios.patch(
            `services/updateSuperCategory?id=${data.id}`,
            data,


        );
        return { ...response.data };
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
            dispatch(removeToken())
            return
        }
        const e = {
            message: error?.response?.data?.message ?? "something went wrong",
            type: "super",
            subType: "update"
        }
        return rejectWithValue(e);
    }
});

// For Deleting contact details of single user from API - AsyncThunk is used For API
export const deleteSuper = createAsyncThunk("deleteContactDetails", async (data, { rejectWithValue, dispatch }) => {
    // here we will fetching the API

    try {
        const response = await defaultAxios.delete(
            `services/deleteSuperCategory?id=${data.id}&p=${data.password}`,
            data,


        );
        return response.data;
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
            dispatch(removeToken())
            return
        }
        const e = {
            message: error?.response?.data?.message ?? "something went wrong",
            type: "super",
            subType: "delete"
        }
        return rejectWithValue(e);
    }
});

export const SuperSlice = createSlice({
    name: "SuperSlice",
    initialState: INIT_STATE,
    reducers: {
        setSearch: (state, action) => {
            console.log(action);
            state.searchData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createSuper.pending, (state) => {
                state.isLoading = true;
                state.error = ""
            })
            .addCase(createSuper.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = ""
                state.superCat.push({ ...action.payload, id: state.superCat.length + 1, category: [] });
            })
            .addCase(createSuper.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getSuper.pending, (state) => {
                state.isLoading = true;
                state.error = ""
            })
            .addCase(getSuper.fulfilled, (state, action) => {
                state.isLoading = false;
                state.superCat = action.payload;
            })
            .addCase(getSuper.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            }).addCase(updateSuper.pending, (state) => {
                state.isLoading = true;
                state.error = ""
            })
            .addCase(updateSuper.fulfilled, (state, action) => {
                state.isLoading = false;
                state.superCat = state.superCat.map((details) => details.id === action.payload.id ? action.payload : details)
            })
            .addCase(updateSuper.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteSuper.pending, (state) => {
                state.isLoading = true;
                state.error = ""
            })
            .addCase(deleteSuper.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.id) {
                    state.superCat = state.superCat.filter((item) => item.id !== action.payload.id)
                }

            })
            .addCase(deleteSuper.rejected, (state, action) => {
                state.isLoading = false;
                console.log(action.payload);
                state.error = action.payload;
            });

    },
});
export default SuperSlice.reducer;
export const { steActive } = SuperSlice.actions;