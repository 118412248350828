import React from "react";

export default function TitleIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M13.5917 3.7019H15.2906C15.7412 3.7019 16.1733 3.8809 16.492 4.19952C16.8106 4.51813 16.9896 4.95027 16.9896 5.40086V17.2935C16.9896 17.7441 16.8106 18.1763 16.492 18.4949C16.1733 18.8135 15.7412 18.9925 15.2906 18.9925H5.0969C4.64631 18.9925 4.21418 18.8135 3.89556 18.4949C3.57695 18.1763 3.39795 17.7441 3.39795 17.2935V5.40086C3.39795 4.95027 3.57695 4.51813 3.89556 4.19952C4.21418 3.8809 4.64631 3.7019 5.0969 3.7019H6.79586"
        stroke="#222222"
        stroke-width="1.35916"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7417 2.00293H7.64489C7.17573 2.00293 6.79541 2.38325 6.79541 2.85241V4.55136C6.79541 5.02051 7.17573 5.40084 7.64489 5.40084H12.7417C13.2109 5.40084 13.5912 5.02051 13.5912 4.55136V2.85241C13.5912 2.38325 13.2109 2.00293 12.7417 2.00293Z"
        stroke="#222222"
        stroke-width="1.35916"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
