import React from "react";

export default function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        opacity="0.3"
        d="M6.89551 6.74902H12.9491V14.249H6.89551V6.74902Z"
        fill="#ED1442"
      />
      <path
        d="M12.5719 2.99854L11.8152 2.24854H8.03163L7.27493 2.99854H4.62646V4.49854H15.2203V2.99854H12.5719ZM5.38317 14.2485C5.38317 15.0735 6.0642 15.7485 6.89658 15.7485H12.9502C13.7826 15.7485 14.4636 15.0735 14.4636 14.2485V5.24854H5.38317V14.2485ZM6.89658 6.74854H12.9502V14.2485H6.89658V6.74854Z"
        fill="#ED1442"
      />
    </svg>
  );
}
