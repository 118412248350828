import {
    createSlice,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import { defaultAxios } from "../../defaultAxios";

const INIT_STATE = {
    tries: 3
};

// For creating new contact -> AsynThunk is used for API

export const login = createAsyncThunk("login", async (data, { rejectWithValue }) => {
    // here we will fetching the API
    try {
        const response = await defaultAxios.post("auth/signIn", data);
        return response.data;
    } catch (error) {

        return rejectWithValue(error?.response ?? error);;
    }
});
export const AuthSlice = createSlice({
    name: "AuthSlice",
    initialState: INIT_STATE,
    reducers: {
        setToken: (state, action) => {
            state['token'] = action.payload;
            state.tries = 3
        },
        removeToken: (state, action) => {
            delete state.token
            window.location.href = "/login"
        },
        setTries: (state, action) => {
            state.tries -= 1
        },
        reSetTries: (state, action) => {
            state.tries = 3
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.token = action.payload;
                state.tries = 3
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                if (action.payload.status === 401) alert('Credentials not correct')
            })

    },

});
export default AuthSlice.reducer;
export const { setToken, removeToken, setTries, reSetTries } = AuthSlice.actions;