import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteContactDetails } from "../../store/slice/contactSlice";

export default function Modal({ open, onClose, userId, children, confirmAction }) {
  useEffect(() => {
    const bodyClasses = document.body.classList
    bodyClasses.add('overflow-y-hidden')
    return () => {
      bodyClasses.remove('overflow-y-hidden')
    }
  }, [])

  return (
    <>
      <div className="w-screen h-screen z-50 flex justify-center items-center bg-[#00000059] fixed top-0 left-0"
        onClick={onClose}
      >
        {children}
      </div>
    </>
  );
}
