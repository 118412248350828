import {
    createSlice,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import { defaultAxios } from "../../defaultAxios";
import { removeToken } from "./AuthSlice";

const INIT_STATE = {
    cat: {},
    subCat: {},
    topic: {},
    super: {}
};

// For creating new contact -> AsynThunk is used for API

export const getCatInfo = createAsyncThunk("getCatInfo", async (data, { rejectWithValue, dispatch }) => {
    // here we will fetching the API
    try {
        const response = await defaultAxios.get("services/getCategoryById?id=" + data, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'realtime': true
            }
        });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
            dispatch(removeToken())
            return
        }
        return rejectWithValue(error?.response ?? error);;
    }
});
export const getSubCatInfo = createAsyncThunk("getSubCatInfo", async (data, { rejectWithValue, dispatch }) => {
    // here we will fetching the API
    try {
        const response = await defaultAxios.get("services/getSubCategoryById?id=" + data, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'realtime': true
            }
        });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
            dispatch(removeToken())
            return
        }
        return rejectWithValue(error?.response ?? error);;
    }
});
export const getTopicInfo = createAsyncThunk("getTopicInfo", async (data, { rejectWithValue, dispatch }) => {
    // here we will fetching the API
    try {
        const response = await defaultAxios.get("services/getTopicById?id=" + data, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'realtime': true
            }
        });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
            dispatch(removeToken())
            return
        }
        return rejectWithValue(error?.response ?? error);;
    }
});
export const getSuperInfo = createAsyncThunk("getSuperInfo", async (data, { rejectWithValue, dispatch }) => {
    // here we will fetching the API
    try {
        const response = await defaultAxios.get("services/getSuperCategoryById?id=" + data, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'realtime': true
            }
        });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
            dispatch(removeToken())
            return
        }
        return rejectWithValue(error?.response ?? error);;
    }
});
export const InfoSlice = createSlice({
    name: "InfoSlice",
    initialState: INIT_STATE,
    reducers: {
        clearInfo: (state, action) => {
            state[action.payload] = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCatInfo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCatInfo.fulfilled, (state, action) => {
                state.cat = action.payload
            })
            .addCase(getCatInfo.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getSubCatInfo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSubCatInfo.fulfilled, (state, action) => {
                state.subCat = action.payload
            })
            .addCase(getSubCatInfo.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getTopicInfo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTopicInfo.fulfilled, (state, action) => {
                state.topic = action.payload
            })
            .addCase(getTopicInfo.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getSuperInfo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSuperInfo.fulfilled, (state, action) => {
                state.super = action.payload
            })
            .addCase(getSuperInfo.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })

    },

});
export default InfoSlice.reducer;
export const { clearInfo } = InfoSlice.actions;