import Button from "./component/Button/Button";
import TabList from "./component/TabList/TabList";
import { useSelector } from "react-redux";
import Table from "./component/Table/Table";
import { removeToken } from "./store/slice/AuthSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import CategoryTable from "./component/CategoryTable/CategoryTable";
import SubCategoryTable from "./component/SubCategoryTable/SubCategoryTable";
import TopicTable from "./component/TopicTable/TopicTable";


// import ContactList from './component/ContactList/ContactList'
export default function Home() {
  const active = useSelector(
    (state) => state.tabs.active
  )
  // import ContactList from './component/ContactList/ContactList'

  const token = useSelector((state) => {
    return state.auth.token
  })
  const navigate = useNavigate()
  useEffect(() => {
    if (!token)
      return navigate('/login')
  }, [token])
  if (token)
    return (
      // <ContactList/>
      <>
        <div className="px-5">
          <TabList active={active} />
          <div
            className="max-w-[1296px] w-full mx-auto bg-white p-8 mt-5 rounded-2xl border border-[#D6D6D6]"
            style={{
              boxShadow:
                "0px 0px 20px 0px rgba(63, 85, 161, 0.08), 0px 2px 4px 0px rgba(46, 77, 157, 0.10)",
            }}
          >
            {/* {active} */}

            <div>
              {
                active === "super" ? <Table /> : active === "category" ? <CategoryTable /> : active === "sub" ? <SubCategoryTable /> : active === "topic" ? <TopicTable /> : ""
              }

            </div>
          </div>
        </div>
      </>
    );
}
