const Switch = ({ title, val, change, color }) => {
    return (
        <div className="flex items-center">
            <label className="relative inline-flex items-center cursor-pointer focus:border-0 focus:outline-none">
                <input
                    onChange={(e) => {
                        change(e.target.checked);
                    }}
                    type="checkbox"
                    checked={val}
                    className="sr-only peer"
                />
                <div
                    className={`w-11 h-6 border bg-white peer-focus:outline-none   rounded-full peer dark:bg-white peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#DDEAFF] after:border-gray-300 after:border-2 after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 ${color ? color : "peer-checked:bg-green-600"
                        } border-[#cbc4c4]`}
                ></div>
                <span className="ms-3 text-sm font-medium text-[#113257]">{title}</span>
            </label>
        </div>
    );
};

export default Switch;
