import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
console.log(BASE_URL);
const url = BASE_URL

export const defaultAxios = axios.create({
    baseURL: url,
});





