import React from "react";

export default function SubTitleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        d="M11.8927 2.56641H5.0969C4.64631 2.56641 4.21418 2.7454 3.89556 3.06402C3.57695 3.38263 3.39795 3.81477 3.39795 4.26536V17.857C3.39795 18.3076 3.57695 18.7397 3.89556 19.0583C4.21418 19.3769 4.64631 19.5559 5.0969 19.5559H15.2906C15.7412 19.5559 16.1733 19.3769 16.492 19.0583C16.8106 18.7397 16.9896 18.3076 16.9896 17.857V7.66326L11.8927 2.56641Z"
        stroke="#222222"
        stroke-width="1.35916"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.8931 2.56641V7.66326H16.9899"
        stroke="#222222"
        stroke-width="1.35916"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5917 11.9106H6.7959"
        stroke="#222222"
        stroke-width="1.35916"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5917 15.3086H6.7959"
        stroke="#222222"
        stroke-width="1.35916"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.49485 8.5127H7.64537H6.7959"
        stroke="#222222"
        stroke-width="1.35916"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
