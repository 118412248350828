import React, { useEffect, useState } from "react";
import { NormalInput } from "../NormalInput";

export default function MenuItem({
    menuItems,
    closeToggle,
    select,
    searchable,
    searchFn,
    inputPlaceHolder,
}) {
    useEffect(() => {
        //adding eventListener to close the dropdown  when clicked outside of it.Used setTimeout so it didn't get close by the opening click
        setTimeout(() => window.addEventListener("click", closeToggle), 0);
        return () => {
            window.removeEventListener("click", closeToggle);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [inputValue, setInputValue] = useState("");
    const [results, setResults] = useState(menuItems ?? []);
    useEffect(() => {
        // Filter API data based on the search input
        const timeOut = setTimeout(() => {
            async function getResults(input) {
                setResults(await searchFn(input));
            }
            if (searchFn) {
                getResults(inputValue);
            } else {
                const filteredResults = menuItems?.filter((option) =>
                    option?.name?.toLowerCase().startsWith(inputValue?.toLowerCase())
                );

                setResults(filteredResults);
            }
        }, 300);
        return () => {
            clearTimeout(timeOut);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    return (
        <div
            className={`z-10 absolute top-10 left-0 bg-white divide-y divide-gray-100 max-h-[15rem] overflow-y-auto  py-1  rounded-b-lg shadow-lg w-full drop-scroll`}
            onClick={(e) => e.stopPropagation()}
        >
            {searchable && (
                <NormalInput
                    type="text"
                    name="search"
                    placeholder={inputPlaceHolder}
                    mainClass="!w-[100%] mx-auto my-2"
                    change={(e) => {
                        setInputValue(e.target.value?.toLowerCase());
                    }}
                    value={inputValue}
                    className="h-[40.5px] placeholder:text-[#86949F] placeholder:text-sm bg-transparent !rounded-md relative font-medium font-SF_Pro_Display text-sm"
                    mainClassDiv="w-full sticky top-[-4px] mx-auto bg-white px-[10px] py-[5px]"
                    icon={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 14 14"
                            fill="none"
                        >
                            <g clip-path="url(#clip0_4205_78897)">
                                <path
                                    d="M13.6299 12.3235L10.577 9.25937C11.4089 8.23814 11.818 6.93504 11.7195 5.61961C11.621 4.30417 11.0226 3.07705 10.0479 2.19206C9.07325 1.30707 7.79697 0.831917 6.48305 0.864889C5.16912 0.897862 3.91809 1.43643 2.98872 2.36921C2.05935 3.30198 1.52274 4.55759 1.48988 5.87631C1.45703 7.19504 1.93045 8.476 2.81222 9.45422C3.69398 10.4324 4.91664 11.0331 6.22727 11.1319C7.53791 11.2308 8.83626 10.8202 9.85377 9.98522L12.9067 13.0493C13.0031 13.1428 13.1323 13.1946 13.2665 13.1934C13.4006 13.1922 13.5288 13.1382 13.6237 13.0431C13.7185 12.9479 13.7723 12.8191 13.7735 12.6845C13.7746 12.5499 13.7231 12.4203 13.6299 12.3235ZM6.61928 10.1197C5.81002 10.1197 5.01893 9.87886 4.34605 9.42761C3.67317 8.97637 3.14873 8.33499 2.83904 7.5846C2.52935 6.8342 2.44832 6.00849 2.6062 5.21187C2.76408 4.41526 3.15377 3.68352 3.72601 3.10919C4.29824 2.53487 5.02731 2.14374 5.82103 1.98529C6.61474 1.82683 7.43744 1.90816 8.1851 2.21898C8.93276 2.5298 9.5718 3.05617 10.0214 3.7315C10.471 4.40684 10.711 5.20082 10.711 6.01305C10.7098 7.10183 10.2783 8.14566 9.5112 8.91555C8.74412 9.68543 7.70409 10.1185 6.61928 10.1197Z"
                                    fill="#6E757B"
                                    stroke="#6E757B"
                                    stroke-width="0.5"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_4205_78897">
                                    <rect
                                        width="13.1519"
                                        height="13.2"
                                        fill="white"
                                        transform="translate(0.628906)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    }
                />
            )}
            <ul className="py-2 text-sm text-white dark:text-black">
                {results?.map((item, index) => (
                    <li
                        key={`${index}-${item.name}`}
                        className="cursor-pointer w-full py-1 px-3 hover:bg-[#F6F8FE] text-[#101D2C] hover:text-primary-blue font-SF_Pro_Display"
                        onClick={(e) => {
                            select(item);
                            closeToggle();
                        }}
                    >
                        {item.name}
                    </li>
                ))}
            </ul>
        </div>
    );
}
