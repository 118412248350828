import React from 'react'
import { useDispatch } from "react-redux";
import { steActive } from '../../store/slice/TabSlice';

export default function TabList({ active }) {
    const dispatch = useDispatch()
    return (
        <div className='max-w-[1296px] w-full mx-auto overflow-x-auto no-scrollbar'>
            <div className='flex gap-11 md:w-full w-[500px] mx-auto pt-36 border-b border-[#E2E4E7] text-[#606060] font-semibold'>
                <p onClick={() => { dispatch(steActive('super')) }} className={`${active === 'super' ? "border-b-4 border-blue-600 pb-[10px] text-[#003C99]" : ""} cursor-pointer capitalize`}>Super Category</p>
                <p onClick={() => { dispatch(steActive('category')) }} className={`${active === 'category' ? " border-b-4 border-blue-600 pb-[10px] text-[#003C99]" : ""} cursor-pointer capitalize`}>category</p>
                <p onClick={() => { dispatch(steActive('sub')) }} className={`${active === 'sub' ? " border-b-4 border-blue-600 pb-[10px] text-[#003C99]" : ""} cursor-pointer capitalize`}>Sub Category</p>
                <p onClick={() => { dispatch(steActive('topic')) }} className={`${active === 'topic' ? " border-b-4 border-blue-600 pb-[10px] text-[#003C99]" : ""} cursor-pointer capitalize`}>Topic</p>

            </div>
        </div>
    )
}
