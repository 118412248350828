import { useForm } from "react-hook-form";
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../FormElements/Input";
import { useEffect, useState } from "react";
import UploadImage from "../../UploadImage/UploadImage";
import Switch from "../../FormElements/Switch";
import Dropdown from "../../FormElements/DropDown/Dropown";
import TitleIcon from "../../Icons/TitleIcon";
import SubTitleIcon from "../../Icons/SubTitleIcon";
import TitleIcon2 from "../../Icons/TitleIcon2";
import { Link, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { clearInfo, getCatInfo } from "../../../store/slice/InfoSlice";
import { getSuper } from "../../../store/slice/SuperSclice";
import { createCat, getCat, updateCat } from "../../../store/slice/CategorySlice";
import FAQs from "../../FAQs/FAQs";
import { clearFaq } from "../../../store/slice/FAQSlice";

// const schema = yup.object().shape({
//     firstName: yup.string().required("Name is required"),
//     lastName: yup.string().required("Name is required"),
//     phone: yup.string().required("Phone number is required"),
//     helpYou: yup.string().required("Tell us, how can we help you."),
//     email: yup
//         .string()
//         .email("Invalid email address")
//         .required("Email is required"),
// });

export default function Edit({ onClose, submit, title }) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      title: "",
      category_slug: "",
      tool_tip: "",
      heading: "",
      sub_heading: "",
      filter_heading: ""
    },
  });
  const [icon, setIcon] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const [error, setError] = useState(false);
  const [slugError, setSlugError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Super, setSuper] = useState(-1);
  const [index, setIndex] = useState(-1);
  const { id } = useParams()
  const [catInfo, SuperCat] = useSelector((state) => [state.info.cat, state.Super.superCat])
  const navigate = useNavigate()

  const dispatch = useDispatch();
  useEffect(() => {
    const getInformation = async () => {//getting cat info and super categories
      if (SuperCat.length === 0) {
        await dispatch(getSuper('order=active'))
      }
      if (id) {
        await dispatch(getCatInfo(id))
      }
    }
    getInformation()

    return () => {
      dispatch(clearInfo('cat'))
      dispatch(clearFaq())
    }
  }, []);

  const slug = watch("category_slug")

  useEffect(() => {
    const timer = setTimeout(() => {
      if (slug.length === 0 || slug.match(/^[a-z0-9\-_]+$/)) {
        setSlugError(false)
      } else {
        setSlugError(true)
      }
    }
      , 300)

    return () => {
      clearTimeout(timer)
    }

  }, [slug])

  useEffect(() => {
    const ind = SuperCat.findIndex((item) => {
      return item.id === catInfo?.super_category?.id;
    });
    setSuper(ind > -1 ? SuperCat[ind]?.id : -1);
    setIndex(ind);
    setValue("name", catInfo?.name ?? "")
    setValue("tool_tip", catInfo?.tool_tip ?? "")
    setValue("title", catInfo?.title ?? "")
    setValue("category_slug", catInfo?.category_slug ?? "")
    setValue("filter_heading", catInfo.filter_heading)
    setValue("sub_heading", catInfo.sub_heading)
    setValue("heading", catInfo.heading)
    setCheckActive(catInfo?.active)
  }, [catInfo])

  const handleIconChange = (iconSrc) => {
    setIcon(iconSrc);
  };

  const handleImageChange = (imageSrc) => {
    setImage(imageSrc);
  };

  const [checkActive, setCheckActive] = useState(catInfo?.active);

  const handleStatusChange = (status) => {
    setCheckActive(status);
  };

  const create = async (Data) => {
    if (Super < 0) {
      setError(true);
      return;
    }
    // submit()
    setLoading(true);
    let submit;
    const formData = new FormData();
    formData.append("name", Data.name);
    formData.append("title", Data.title);
    formData.append("category_slug", Data.category_slug);
    formData.append("sub_heading", Data.sub_heading);
    formData.append("heading", Data.heading);
    formData.append("filter_heading", Data.filter_heading);
    formData.append("tool_tip", Data.tool_tip);
    formData.append("super_categoryId", Super);
    if (id) {
      // console.log(icon, image);
      formData.append("active", checkActive);
      if (Data.category_slug === catInfo.category_slug) formData.delete("category_slug")
      if (typeof icon === "object") {
        formData.append("icon", icon);
        formData.append(
          "oldIcon",
          catInfo?.icon?.slice(0, 14) === "service-icons/"
            ? catInfo.icon
            : "service-icons/" + catInfo.icon
        );
      }
      if (typeof image === "object") {
        formData.append("image", image);
        formData.append("oldImage", catInfo.image_key);
      }
      submit = await dispatch(updateCat({ id: id, formData }));
    } else {
      formData.append("icon", icon);
      formData.append("image", image);
      submit = await dispatch(createCat({ formData }));
    }
    // onClose()  
    if (submit?.payload.type === 'success') {
      dispatch(getCat('order=active'))
      navigate('/')
    }
    else {
      alert(submit?.payload?.message ?? "something went wrong")
    }

    // console.log(formData);
    setLoading(false);
  };
  const [tab, setTab] = useState("page-details");
  const handelTabValue = (tabValue) => {
    if (tab != tabValue) {
      setTab(tabValue);
      return;
    }
  };
  return (
    <section className="bg-[#F7FBFF] pt-[82px] pb-7 md:pb-16">
      <div className="max-w-[1300px] w-full mx-auto px-5">
        <div>
          <h1 className="text-[#111111] font-bold text-[22px] mt-9 mb-6">
            {id ? "Edit" : "Add"} Category
          </h1>
        </div>

        <div
          className="bg-white rounded-md mb-6"
          style={{
            boxShadow:
              "0px 0px 2px 0px rgba(18, 34, 59, 0.20), 0px 2px 14px 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <div className="px-5 py-8">
            <div>
              <ul className="flex gap-3">
                <li
                  className={`py-5 border-b-[3px] mr-3 ${tab == "page-details"
                    ? "border-[#1057CB]"
                    : "border-transparent"
                    }`}
                >
                  <button onClick={() => handelTabValue("page-details")}>
                    Page Details
                  </button>
                </li>
                {id && <li
                  className={`py-5 border-b-[3px] ${tab == "faq-details"
                    ? "border-[#1057CB]"
                    : "border-transparent"
                    }`}
                >
                  <button onClick={() => handelTabValue("faq-details")}>
                    FAQ Details
                  </button>
                </li>}
              </ul>
              <hr />
              <div className="md:pt-3">
                <div
                  className={` ${tab == "page-details" ? "block" : "hidden"}`}
                >
                  <form
                    onSubmit={handleSubmit(create)}
                    className="mt-3 md:mb-5"
                  >
                    <div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="w-full relative rounded-3xl bg-white"
                      >
                        <p className="text-[17px] font-medium py-3 text-[#113257]">
                          Category Image<span className=" text-red-600">*</span>
                        </p>
                        <div className="flex gap-3 mb-6 md:flex-row flex-col">
                          <div className="flex gap-3">
                            <div className="bg-white p-[2px] h-20 w-20 border rounded-lg flex justify-center items-center">
                              <UploadImage
                                onImageChange={handleIconChange}
                                url={
                                  catInfo?.icon &&
                                  `${process.env.REACT_APP_IMG_URL}${catInfo?.icon?.slice(0, 14) ===
                                    "service-icons/"
                                    ? catInfo.icon
                                    : `service-icons/${catInfo.icon}`
                                  }`
                                }
                              />
                            </div>
                            <div className="bg-white p-[2px] h-20 w-20 border rounded-lg flex justify-center items-center">
                              <UploadImage
                                onImageChange={handleImageChange}
                                url={
                                  catInfo?.image_key &&
                                  `${process.env.REACT_APP_IMG_URL}${catInfo?.image_key}`
                                }
                              />
                            </div>
                          </div>
                          <div className="bg-[#FFF7EF] pl-4 pr-9 py-4 rounded-xl md:w-6/12 w-full text-base">
                            <p>
                              Set the category thumbnail image. Only *.png,
                              *.jpg and *.jpeg image files are accepted
                            </p>
                          </div>
                        </div>

                        {/* <input type='file' name='icon' {...register} onChange={(e) => { setIcon(e.target.files[0]) }} />
                    <input type='file' name='image' {...register} onChange={(e) => { setImage(e.target.files[0]) }} /> */}
                        <input type="submit" className="hidden" />
                        <div className="md:flex gap-6">
                          <div className="pb-5 w-full md:w-1/2">
                            <Input
                              type="text"
                              placeholder="Category Name"
                              className="h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]"
                              register={register}
                              name="name"
                              label="Category Name    "
                              requiredIcon="*"
                              labelClass="text-[#334055] font-semibold"
                              error={errors?.name}
                              inputBg="bg-transparent"
                            />
                          </div>
                          <div className="pb-5 w-full md:w-1/2">
                            <Input
                              type="text"
                              placeholder="Ex. /app-development"
                              className="h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]"
                              register={register}
                              name="category_slug"
                              label="Slug"
                              requiredIcon="*"
                              labelClass="text-[#334055] font-semibold"
                              error={errors?.slug}
                              inputBg="bg-transparent"
                            />
                            {slugError && <p className="text-red-500">no special character allowed</p>}

                          </div>
                        </div>
                        <div className="md:flex gap-6">
                          <div
                            className={`pb-5 ${id ? "md:w-1/2 w-full" : "w-full"
                              }`}
                          >
                            <Input
                              type="text"
                              placeholder="Title"
                              className="h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]"
                              register={register}
                              name="title"
                              label="Title"
                              requiredIcon="*"
                              labelClass="text-[#334055] font-semibold"
                              error={errors?.title}
                              inputBg="bg-transparent"
                            />
                          </div>
                          <div
                            className={`pb-5 w-full md:w-1/2 ${id ? "block" : "hidden"
                              }`}
                          >
                            <div className="flex justify-between items-center">
                              <p class="pb-2 font-semibold">
                                Status <span class="text-[#DB3E3E]">*</span>
                              </p>
                              <div
                                className={`w-3 h-3 rounded-full ${checkActive ? "bg-[#09C26C]" : "bg-red-600"
                                  }`}
                              ></div>
                            </div>
                            <Switch
                              val={checkActive}
                              change={handleStatusChange}
                              title="Active/Inactive"
                            />
                          </div>
                        </div>
                        <div>
                          <p className="pb-2 text-[#334055] font-semibold">
                            Super Category{" "}
                            <span className="text-[#DB3E3E]">*</span>
                          </p>
                          <Dropdown
                            className="w-full h-full"
                            searchable={true}
                            buttonClass="h-[50px] !border-[#dcdcdc]"
                            click={(item) => {
                              setSuper(item.id);
                              setError(false);
                            }}
                            all={[]}
                            placeHolder={
                              catInfo
                                ? SuperCat[index]
                                  ? SuperCat[index]?.name
                                  : "Select a super category"
                                : "Select a super category"
                            }
                            options={SuperCat}
                          />
                        </div>

                        {error && (
                          <p className="text-red-600">
                            Super category is required
                          </p>
                        )}
                        <div className="mb-5 mt-5">
                          <Input
                            type="text"
                            placeholder="Category Description"
                            className="h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]"
                            register={register}
                            name="tool_tip"
                            label="Tooltip"
                            requiredIcon="*"
                            labelClass="text-[#334055] font-semibold"
                            error={errors?.tooltip}
                            inputBg="bg-transparent"
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="mt-3" />
                    <h2 className="my-5 text-base md:text-lg font-medium">
                      Page Details
                    </h2>
                    <label
                      htmlFor="title"
                      className="text-[#222222] text-base flex gap-2 font-medium items-center mb-3"
                    >
                      <TitleIcon /> Heading
                      <span className="text-[#EA0000] ">*</span>
                    </label>
                    <Input
                      type="text"
                      placeholder="Write here"
                      className="h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]"
                      register={register}
                      name="heading"
                      requiredIcon="*"
                      labelClass="text-[#334055] font-semibold"
                      error={errors?.name}
                      inputBg="bg-transparent"
                    />

                    <label
                      htmlFor="subTitle"
                      className="text-[#222222] text-base flex gap-2 font-medium items-center mb-3 mt-5"
                    >
                      <SubTitleIcon /> Sub Heading
                      <span className="text-[#EA0000] ">*</span>
                    </label>
                    <Input
                      type="text"
                      placeholder="Sub heading"
                      className="h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]"
                      register={register}
                      name="sub_heading"
                      requiredIcon="*"
                      labelClass="text-[#334055] font-semibold"
                      error={errors?.name}
                      inputBg="bg-transparent"
                    />

                    <label
                      htmlFor="title2"
                      className="text-[#222222] text-base flex gap-2 font-medium items-center mb-3 mt-5"
                    >
                      <TitleIcon2 /> Filter Heading
                      <span className="text-[#EA0000] ">*</span>
                    </label>
                    <Input
                      type="text"
                      placeholder="Write here"
                      className="h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]"
                      register={register}
                      name="filter_heading"
                      requiredIcon="*"
                      labelClass="text-[#334055] font-semibold"
                      error={errors?.name}
                      inputBg="bg-transparent"
                    />
                    <div className="flex justify-between items-center mt-6">
                      <button
                        disabled={loading || slugError}
                        className={`text-white disabled:cursor-not-allowed font-semibold rounded-full border-0 bg-primary-green w-[136px] h-[40px] md:min-h-[40px] `}
                      >
                        Save
                      </button>
                      <Link to={'/'}>
                        <button
                          className={`text-[#323232] font-semibold rounded-full border-0 bg-[#EAEAEA] w-[136px] h-[40px] md:min-h-[40px] `}
                        >
                          Back
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
                {id && <div
                  className={` ${tab == "faq-details" ? "block" : "hidden"}`}>
                  <FAQs type={'category'} id={id} />
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
