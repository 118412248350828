import {
    createSlice,
    createAsyncThunk
} from "@reduxjs/toolkit";
import { defaultAxios } from "../../defaultAxios";
import { removeToken } from "./AuthSlice";

const INIT_STATE = {
    Topics: [],
    isLoading: false,
    error: null,
    searchData: ""
};

// For creating new contact -> AsynThunk is used for API

export const createTopic = createAsyncThunk(
    "createTopic",
    async (data, { rejectWithValue, dispatch }) => {
        //we are fetching Api to POST the detail
        try {
            const response = await defaultAxios.post(
                "services/createTopic",
                data,

            );
            return { ...response.data, ...data };
        } catch (error) {
            if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
                dispatch(removeToken())
                return
            }
            console.log(error);
            const e = {
                message: error?.response?.data?.message ?? "something went wrong",
                type: "topic",
                subType: "create"
            }
            return rejectWithValue(e);
        }
    }
);

// For reading All the contact list from API - AsyncThunk is used For API

export const getTopic = createAsyncThunk("getTopic", async (data, { rejectWithValue, dispatch }) => {
    // here we will fetching the API
    try {
        const response = await defaultAxios.get("services/allTopics?" + data, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'realtime': true
            }
        });
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
            dispatch(removeToken())
            return
        }
        console.log(error);
        const e = {
            message: error?.response?.data?.message ?? "something went wrong",
            type: "topic",
            subType: "get"
        }
        return rejectWithValue(e);
    }
});

// For updaing contact details of single user from API - AsyncThunk is used For API
export const updateTopic = createAsyncThunk("updateTopic", async (data, { rejectWithValue, dispatch }) => {
    // here we will fetching the API
    try {

        const response = await defaultAxios.patch(
            `services/updateTopic?id=${data.id}`,
            data,
        );
        return { ...response.data };
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
            dispatch(removeToken())
            return
        }
        const e = {
            message: error?.response?.data?.message ?? "something went wrong",
            type: "topic",
            subType: "update"
        }
        return rejectWithValue(e);
    }
});

// For Deleting contact details of single user from API - AsyncThunk is used For API
export const deleteTopic = createAsyncThunk("deleteContactDetails", async (data, { rejectWithValue, dispatch }) => {
    // here we will fetching the API
    try {
        const response = await defaultAxios.delete(
            `services/deleteTopic?id=${data.id}&p=${data.password}`,
        );
        return response.data
    } catch (error) {
        if (error?.response?.data?.message === 'Token Expired!!' || error?.response?.data?.message === "Unauthorized") {
            dispatch(removeToken())
            return
        }
        console.log(error);
        const e = {
            message: error?.response?.data?.message ?? "something went wrong",
            type: "topic",
            subType: "delete"
        }
        return rejectWithValue(e);
    }
});

export const TopicSlice = createSlice({
    name: "TopicSlice",
    initialState: INIT_STATE,
    reducers: {
        setSearch: (state, action) => {
            console.log(action);
            state.searchData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createTopic.pending, (state) => {
                state.isLoading = true;
                state.error = ""
            })
            .addCase(createTopic.fulfilled, (state, action) => {
                state.isLoading = false;
                console.log(action.payload);
                state.Topics.push({ ...action.payload, id: state.Topics.length + 1, category: [] });
            })
            .addCase(createTopic.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getTopic.pending, (state) => {
                state.isLoading = true;
                state.error = ""
            })
            .addCase(getTopic.fulfilled, (state, action) => {
                state.isLoading = false;
                state.Topics = action.payload;
            })
            .addCase(getTopic.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            }).addCase(updateTopic.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateTopic.fulfilled, (state, action) => {
                state.isLoading = false;
                state.Topics = state.Topics.map((details) => details.id === action.payload.id ? action.payload : details)
            })
            .addCase(updateTopic.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteTopic.pending, (state) => {
                state.isLoading = true;
                state.error = ""
            })
            .addCase(deleteTopic.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.id) {
                    state.Topics = state.Topics.filter((item) => item.id !== action.payload.id)
                }

            })
            .addCase(deleteTopic.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload
            });

    },
});
export default TopicSlice.reducer;
export const { steActive } = TopicSlice.actions;