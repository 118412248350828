import {
  createSlice,
} from "@reduxjs/toolkit";

const INIT_STATE = {
  active: "category",
};

export const TabSlice = createSlice({
  name: "TabList",
  initialState: INIT_STATE,
  reducers: {
    steActive: (state, action) => {
      console.log(action);
      state.active = action.payload;
    },
  },
});
export default TabSlice.reducer;
export const { steActive } = TabSlice.actions;