import Header from "./component/Header/Header";
import Footer from "./component/Footer/Footer";
import Home from "./Home";
import Create from "./Create";
import "./App.css";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./component/Login/Login";
import Edit from "./component/Modal/Screens/Edit";
import SuperModal from "./component/Modal/Screens/SuperModal";
import SubModal from "./component/Modal/Screens/SubModal";
import TopicModal from "./component/Modal/Screens/TopicModal";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Toaster position="top-center" />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/create" element={<Create />}></Route>
          <Route path="/login" element={<Login />}></Route>
          {/* <Route path="/edit/:id" element={<Update />}></Route> */}
          <Route path="/edit">
            <Route path="category/:id" element={<Edit />} />
            <Route path="super-category/:id" element={<SuperModal />} />
            <Route path="sub-category/:id" element={<SubModal />} />
            <Route path="topic/:id" element={<TopicModal />} />
          </Route>
          <Route path="/add">
            <Route path="category" element={<Edit />} />
            <Route path="super-category" element={<SuperModal />} />
            <Route path="sub-category" element={<SubModal />} />
            <Route path="topic" element={<TopicModal />} />
          </Route>

        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
