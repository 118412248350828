import React, { useEffect, useRef, useState } from "react";
import DeleteIcon from "../Icons/DeleteIcon";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NormalInput } from "../FormElements/NormalInput";
import { useDispatch, useSelector } from "react-redux";
import { deleteFaqs, getFaqs, modifyFaqs, removeFaq, updateFaq } from "../../store/slice/FAQSlice";
import { nanoid } from "@reduxjs/toolkit";
import toast from "react-hot-toast";


export default function FAQs({ type, id }) {
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [faqs, setFaqs] = useState([]); //if item in array is string, means it is a new FAQ else it is old
    const dispatch = useDispatch();
    const [FAQs, loading] = useSelector((state) => [state.faq.faqs, state.faq.isLoading]);
    const FAQsObj = useRef({})
    useEffect(() => {
        //fetching FAQs
        if (id)
            dispatch(getFaqs(`${type}Id=${id}`));
    }, []);
    useEffect(() => {
        const answers = []
        const questions = []
        FAQsObj.current = {}
        console.log(FAQs, "<====="
        )
        if (FAQs.length === 0) {
            setAnswers([''])
            setQuestions([''])
            setFaqs([nanoid()])
            return
        }
        const newFaqs = FAQs?.map((item) => {
            answers.push(item.content)
            questions.push(item.title)
            FAQsObj.current[item.id] = {
                answer: item.title,
                question: item.content
            }
            return item.id
        });
        console.log(answers, "<====");
        setAnswers(answers)
        setQuestions(questions)
        setFaqs(newFaqs)
    }, [FAQs]);

    const AddNewFAQ = () => {
        const newFaqs = faqs;
        newFaqs.push(nanoid());
        setFaqs([...newFaqs]);
    };

    const deleteFAQ = async (id, index) => {
        toast.loading("Deleting", {
            id: 69
        })
        if (id > 0) {
            await dispatch(deleteFaqs(id))
            delete FAQsObj.current[id]
        }
        answers.splice(index, 1)
        questions.splice(index, 1)
        faqs.splice(index, 1)
        setAnswers([...answers])
        setQuestions([...questions])
        setFaqs([...faqs])
        dispatch(removeFaq(index))
        toast.success("Deleted successfully", {
            id: 69
        })
    }


    const saveFaqs = async () => {
        toast.loading("Saving", {
            id: 69
        })
        const body = []
        let error = false
        let answerError = false
        questions.forEach((question, index) => {
            if (question.includes('{') && !question.includes('{location}')) {
                error = index + 1
            }
            if (answers?.[index]?.includes('{') && !answers?.[index]?.includes('{location}')) {
                answerError = index + 1
            }
            if (isNaN(-faqs?.[index])) {
                body.push({
                    [`${type}Id`]: id,
                    question,
                    answer: answers[index]
                })
                return
            }
            else if (FAQsObj.current[faqs?.[index]]?.answer !== answers[index] || FAQsObj.current[faqs?.[index]]?.question !== questions[index]) {
                body.push({
                    id: faqs?.[index],
                    question,
                    answer: answers[index]
                })
            }
        })
        if (error) {
            toast.error(`Invalid {location} in question number ${error} `, {
                id: 69
            })
            return
        }
        if (answerError) {
            toast.error(`Invalid {location} in answer number ${answerError} `, {
                id: 69
            })
            return
        }
        await dispatch(updateFaq(body))
        await dispatch(getFaqs(`${type}Id=${id}`))
        toast.success("Saved successful", {
            id: 69
        })
    }

    return (
        <>
            <div className="flex justify-between items-center">
                <h2 className="my-5 text-base md:text-lg font-medium">FAQ Details</h2>

            </div>
            {faqs?.map((item, index) => {
                return (
                    <div
                        key={`${item}-${index}`}
                        className="bg-[#FBFBFB] my-2 rounded-xl border border-[#dae1ee] p-6"
                        style={{
                            boxShadow:
                                "0px 2px 4px 0px rgba(10, 12, 15, 0.04), 0px 0px 12px 0px rgba(126, 136, 174, 0.08)",
                        }}
                    >
                        <div className="flex md:flex-row flex-col gap-5">
                            <div className="md:w-[45%] flex gap-3 md:flex-row flex-col">
                                <div className="w-[10%] hidden md:block">
                                    <div className="w-[40px] h-[40px] rounded-full flex justify-center items-center font-bold text-[#1057CB] bg-[#F1F5FF] mt-10">
                                        {index + 1}
                                    </div>
                                </div>
                                <div className="flex gap-3 md:hidden items-center">
                                    <div className="w-[40px] h-[40px] rounded-full flex justify-center items-center font-bold text-[#1057CB] bg-[#F1F5FF]">
                                        {index + 1}
                                    </div>
                                </div>
                                <NormalInput
                                    type="text"
                                    placeholder="Faq Question"
                                    className="h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]"
                                    name="faqTitle"
                                    label="Title"
                                    value={questions[index] ?? ""}
                                    change={(e) => {
                                        const newQuestions = questions;
                                        newQuestions[index] = e.target.value;
                                        setQuestions([...newQuestions]);
                                    }}
                                    labelClass="text-[#334055] font-semibold text-[#002257] hidden md:block"
                                    inputBg="bg-transparent"
                                    mainClassDiv="w-full md:w-[90%]"
                                    mainClass="!pr-0"
                                />
                            </div>
                            <div className="md:w-[55%] flex gap-3 justify-between flex-col md:flex-row">
                                <div className="md:w-[93%] w-full">
                                    <label
                                        htmlFor="title2"
                                        className="text-[#002257] text-base mb-2 md:block font-semibold hidden"
                                    >
                                        Description
                                    </label>
                                    <div className="flex gap-3 md:hidden items-center">
                                        <label
                                            htmlFor="faqTitle"
                                            className="text-[#002257] text-base block font-semibold"
                                        >
                                            Description
                                        </label>

                                    </div>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={answers[index] ?? ""}
                                        config={{
                                            toolbar: [
                                                "bold",
                                                "italic",
                                                "underline",
                                                "numberedList",
                                                "bulletedList",
                                            ],
                                        }}
                                        onChange={(_event, editor) => {
                                            const newAnswers = answers;
                                            newAnswers[index] = editor.getData();
                                            setAnswers([...newAnswers]);
                                            // field.onChange(editor.getData());
                                        }}
                                    />
                                </div>
                                <div className="w-[7%] hidden md:block text-right">
                                    <div onClick={() => deleteFAQ(item, index)} className="w-[40px] cursor-pointer h-[40px] rounded-full flex justify-center items-center font-bold text-[#ED1442] bg-[#FFD3E254] mt-14 ">
                                        <DeleteIcon />
                                    </div>
                                </div>
                                <div onClick={() => deleteFAQ(item, index)} className="w-[40px] h-[40px] cursor-pointer rounded-full flex justify-center items-center font-bold text-[#ED1442] bg-[#FFD3E254] mb-2 md:hidden mr-0 ml-auto">
                                    <DeleteIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            <div className="flex gap-2 justify-start items-center mt-8">
                {faqs.length > 0 && <button
                    onClick={saveFaqs}
                    disabled={loading}
                    className={`text-white disabled:cursor-not-allowed font-semibold rounded-full border-0 bg-primary-green w-[136px] h-[40px] md:min-h-[40px] `}
                >
                    Save
                </button>}
                <button
                    onClick={AddNewFAQ}
                    className="flex gap-2 text-[#1057CB] border rounded-full border-[#1057CB] w-[168px] h-[40px] justify-center items-center"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                    >
                        <path
                            d="M15.313 10.2412H10.483V14.6643H8.87297V10.2412H4.04297V8.76683H8.87297V4.34375H10.483V8.76683H15.313V10.2412Z"
                            fill="#1057CB"
                        />
                    </svg>
                    Add New FAQ
                </button>
            </div>
        </>
    );
}
