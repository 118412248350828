/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { MdArrowDropDown } from "react-icons/md";
import MenuItem from "./MenueItem";
import { all } from "axios";
// import { menuItems } from "@/constant/filter.constant";
// import { truncateText } from "@/utils/global";

const Dropdown = ({
    className,
    options,
    placeHolder,
    viewPlaceHolder = "Search",
    click,
    filter,
    searchable,
    buttonClass,
    searchFn,
    all,
    truncateLength = 12,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(
        placeHolder !== ""
            ? placeHolder
            : options[0]?.name
    );

    useEffect(() => {
        // setSelected(filter[])
        setSelected(
            placeHolder !== ""
                ? placeHolder
                : options[0]?.name
        );
    }, [placeHolder]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const closeDropdown = () => {
        setIsOpen(false);
    };

    return (
        <div className={`relative inline-block text-left ${className} `}>
            <button
                onClick={toggleDropdown}
                className={`text-[#86949F] w-full bg-white border-[1px] border-[#DCDCDC] hover:border-primary-blue transition-all justify-between  focus:outline-none  font-medium rounded-md text-[14px] px-3 py-2.5 text-center inline-flex items-center font-SF_Pro_Display ${buttonClass}`}
                type="button"
            >
                {/* {truncateText(selected, truncateLength)} */}{selected}
                <MdArrowDropDown
                    size={20}
                    className={`${isOpen && "rotate-180"} text-[#222]`}
                />
            </button>

            {/* Dropdown menu */}
            {isOpen && (
                <MenuItem
                    menuItems={[all, ...options]} //if options is string we need to fetch them from our local json else it is fetched from api
                    closeToggle={closeDropdown}
                    searchable={searchable}
                    inputPlaceHolder={viewPlaceHolder}
                    searchFn={searchFn}
                    select={(item) => {
                        if (click) click(item);
                        setSelected(item.name);
                    }}
                />
            )}
        </div>
    );
};

export default Dropdown;
