import React from "react";

export default function TitleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M3.39795 16.5649C3.39795 16.0016 3.62169 15.4614 4.01996 15.0632C4.41823 14.6649 4.9584 14.4412 5.52164 14.4412H16.9896"
        stroke="#222222"
        stroke-width="1.35916"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.52164 1.69897H16.9896V18.6885H5.52164C4.9584 18.6885 4.41823 18.4648 4.01996 18.0665C3.62169 17.6682 3.39795 17.1281 3.39795 16.5648V3.82267C3.39795 3.25943 3.62169 2.71926 4.01996 2.32099C4.41823 1.92272 4.9584 1.69897 5.52164 1.69897V1.69897Z"
        stroke="#222222"
        stroke-width="1.35916"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
