import Close from '../../Icons/Close'
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from '../../FormElements/Input';
import { useEffect, useState } from 'react';
import Switch from '../../FormElements/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { createSuper, getSuper, updateSuper } from '../../../store/slice/SuperSclice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { clearInfo, getSuperInfo } from '../../../store/slice/InfoSlice';

// const schema = yup.object().shape({
//     firstName: yup.string().required("Name is required"),
//     lastName: yup.string().required("Name is required"),
//     phone: yup.string().required("Phone number is required"),
//     helpYou: yup.string().required("Tell us, how can we help you."),
//     email: yup
//         .string()
//         .email("Invalid email address")
//         .required("Email is required"),
// });

export default function SuperModal() {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: ""
        }
    });
    const { id } = useParams()

    const [SuperCat, loading] = useSelector((state) => [state.info.super, state.Super.isLoading])
    console.log(loading);
    const navigate = useNavigate()
    useEffect(() => {
        console.log(id);
        const getInfo = async () => {
            if (id)
                await dispatch(getSuperInfo(id))
        }
        getInfo()

        return () => {
            dispatch(clearInfo('super'))
        }
    }, [])
    useEffect(() => {//setting info when details are fetched
        setValue("name", SuperCat.name)
        setCheckActive(SuperCat.active)
    }, [SuperCat])
    const dispatch = useDispatch()
    const create = async (Data) => {
        let submit
        if (id) {
            const data = { name: Data.name, active: checkActive, id: SuperCat.id }
            submit = await dispatch(updateSuper(data))
        } else {
            submit = await dispatch(createSuper(Data))
        }
        console.log(submit);
        if (submit?.payload.type === 'success') {
            await dispatch(getSuper('order=active'))
            navigate('/')
        }
        else {
            alert(submit?.payload?.message ?? "something went wrong")
        }

        // onClose()
    }
    const [checkActive, setCheckActive] = useState(SuperCat?.active ?? false);
    const handleStatusChange = (status) => {
        setCheckActive(status);
    }
    return (
        <section className="bg-[#F7FBFF] pt-[82px] pb-7 md:pb-16">
            <div className="max-w-[1300px] w-full mx-auto px-5">
                <div>
                    <h1 className="text-[#111111] font-bold text-[22px] mt-9 mb-6">
                        {id ? "Edit" : "Add"} Super Category
                    </h1>
                </div>
                <div
                    className="bg-white rounded-md mb-6 overflow-hidden px-5 py-5"
                    style={{
                        boxShadow:
                            "0px 0px 2px 0px rgba(18, 34, 59, 0.20), 0px 2px 14px 0px rgba(0, 0, 0, 0.06)",
                    }}
                >
                    <div onClick={(e) => { e.stopPropagation() }} className='w-full bg-white'>


                        <form onSubmit={handleSubmit(create)} className='mt-3 mb-4'>
                            <input type='submit' className='hidden' />
                            <div className='flex gap-3 md:flex-row flex-col'>
                                <div className={`${id ? 'md:w-1/2 w-full' : 'w-full'}`}>
                                    <Input
                                        type="text"
                                        placeholder="Name"
                                        className={`h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]`}
                                        register={register}
                                        name="name"
                                        label="Super Category Name"
                                        requiredIcon="*"
                                        labelClass="text-[#334055] font-semibold"
                                        error={errors?.tooltip}
                                        inputBg="bg-transparent"
                                    />
                                </div>
                                <div className={`pb-5 w-full md:w-1/2 ${id ? 'block' : 'hidden'}`}>
                                    <div className='flex justify-between items-center'>
                                        <p class="pb-2 font-semibold">Status <span class="text-[#DB3E3E]">*</span></p>
                                        <div className={`w-3 h-3 rounded-full ${checkActive ? "bg-[#09C26C]" : "bg-red-600"}`}></div>
                                    </div>
                                    <Switch val={checkActive} change={handleStatusChange} title="Active/Inactive" />
                                </div>
                            </div>
                            {/* {id && <Switch title="Status" val={status} change={(val) => {
                        console.log(val);
                        setStatus(val)
                    }} />} */}

                            <div className='flex justify-between items-center mt-6'>
                                <button
                                    disabled={loading}
                                    className={`text-white disabled:cursor-not-allowed font-semibold rounded-full border-0 bg-primary-green w-[136px] h-[40px] md:min-h-[40px] `}
                                >
                                    Save
                                </button>
                                <Link to={'/'}>
                                    <button
                                        className={`text-[#323232] font-semibold rounded-full border-0 bg-[#EAEAEA] w-[136px] h-[40px] md:min-h-[40px] `}
                                    >
                                        Back
                                    </button>
                                </Link>


                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </section>
    )
}
