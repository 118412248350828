import {
    createSlice,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import { defaultAxios } from "../../defaultAxios";

const INIT_STATE = {
    faqs: [],
    isLoading: false,
    error: {}
};

// For creating new contact -> AsynThunk is used for API

export const getFaqs = createAsyncThunk("getFAQs", async (data, { rejectWithValue }) => {
    // here we will fetching the API
    try {
        const response = await defaultAxios.get("services/getFaqById?" + data, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'realtime': true
            }
        });
        return response.data;
    } catch (error) {

        return rejectWithValue(error?.response ?? error);;
    }
});
export const deleteFaqs = createAsyncThunk("deleteFaqs", async (data, { rejectWithValue }) => {
    // here we will fetching the API
    try {
        const response = await defaultAxios.delete("services/deleteFaq?id=" + data, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            }
        });
        return response.data;
    } catch (error) {

        return rejectWithValue(error?.response ?? error);;
    }
});
export const updateFaq = createAsyncThunk("updateFaq", async (data, { rejectWithValue }) => {
    // here we will fetching the API
    try {
        const response = await defaultAxios.patch("services/updateFaq", data, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            }
        });
        return response.data;
    } catch (error) {

        return rejectWithValue(error?.response ?? error);;
    }
});

export const FAQSlice = createSlice({
    name: "FAQSlice",
    initialState: INIT_STATE,
    reducers: {
        clearFaq: (state, action) => {
            console.log("fgddfggdf");
            state.faqs = [];
        },
        modifyFaqs: (state, action) => {
            console.log("fgddfggdf444343");
            state.faqs = action.payload
        },
        removeFaq: (state, action) => {
            console.log("fgddfggdf;'';;'';';;'");
            if (action.payload < state.faqs.length)
                state.faqs.splice(action.payload, 1)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFaqs.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFaqs.fulfilled, (state, action) => {
                state.faqs = action.payload
                state.isLoading = false
            })
            .addCase(getFaqs.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteFaqs.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteFaqs.fulfilled, (state, action) => {
                state.isLoading = false
            })
            .addCase(deleteFaqs.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(updateFaq.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateFaq.fulfilled, (state, action) => {
                state.isLoading = false
            })
            .addCase(updateFaq.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    },

});
export default FAQSlice.reducer;
export const { clearFaq, modifyFaqs, removeFaq } = FAQSlice.actions;