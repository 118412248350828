import React, { useState } from 'react'
import { NormalInput } from '../../FormElements/NormalInput'
import { useDispatch, useSelector } from 'react-redux'
import { removeToken } from '../../../store/slice/AuthSlice'


export default function DeleteModal({ data, deleteItem, error, onClose }) {
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const tries = useSelector(state => state.auth.tries)
    const deleteFn = (e) => {
        console.log(e);
        e.preventDefault()
        const info = {
            password,
            id: data.id
        }
        deleteItem(info)
    }
    if (tries === 0) {
        dispatch(removeToken())
    }
    console.log(error);
    return (
        <div className='tab:w-[600px] md:w-3/4 w-full bg-white py-4 px-5 rounded-3xl flex justify-center items-center flex-col' onClick={(e) => e.stopPropagation()}>
            <h1 className='text-center text-2xl font-bold'>Confirm to delete {data.name}</h1>
            <form className='md:w-3/4 w-full' onSubmit={deleteFn}>
                <NormalInput
                    label="Password"
                    mainClass="!w-[100%] mx-auto my-2"
                    className="h-[40.5px] placeholder:text-[#86949F] placeholder:text-sm bg-transparent !rounded-md relative font-medium font-SF_Pro_Display text-sm"
                    mainClassDiv="w-full sticky top-[-4px] mx-auto bg-white px-[10px]"
                    required={true}
                    placeholder="Confirm your password"
                    type="password"
                    change={(e) => setPassword(e.target.value)}
                    value={password}
                />
                {error.subType === "delete" && <p className='pb-2 px-[10px] text-red-600'>{error.message === 'Unauthorized' ? `${tries} tries left` : error.message}</p>}
                <div className='flex gap-3'>

                    <button className='bg-red-600 rounded-full px-4 py-2 text-white font-semibold w-20 md:w-40 h-[40px]'>Delete</button>
                    <button onClick={onClose} className='bg-greytext-[#323232] font-semibold rounded-full border-0 bg-[#EAEAEA] w-20 md:w-40 h-[40px] md:min-h-[40px]' >Cancel</button>
                </div>
            </form>

        </div>
    )
}
