import { useState } from "react";


export function Input({
    mainClassDiv,
    className,
    placeholder,
    type,
    register,
    name,
    label,
    error,
    icon,
    labelClass,
    requiredIcon = "",
    focus = false,
    inputBg = "",
    reference,
    id,
}) {
    const [isFocused, setFocused] = useState(false);

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };
    return (
        <>
            <div className={mainClassDiv}>
                {label ? ( // showing label only if passed as a prop
                    <label className={`text-left ${labelClass}`}>
                        <p className="pb-2">
                            {label} <span className="text-[#DB3E3E]">{requiredIcon}</span>
                        </p>
                    </label>
                ) : null}
                <div
                    className={`appearance-none border flex items-center  ${isFocused ? "!border-primary-blue" : "!border-[#DCDCDC]"
                        } rounded-lg w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-SF_Pro_Display text-[15px] ${className}`}
                >
                    <input
                        placeholder={placeholder}
                        {...register(name)}
                        className={`w-full focus:outline-none py-2 px-3 ${inputBg}`}
                        autoComplete="off"
                        type={type}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        autoFocus={focus}
                        id={id}
                    // ref={reference}

                    required
                    />
                    {icon ? icon : null}
                </div>
                <p className="text-red-500 text-sm px-3 text-left">{error?.message}</p>
            </div>
        </>
    );
}

