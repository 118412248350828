import Close from "../../Icons/Close";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../FormElements/Input";
import { useEffect, useState } from "react";
import Switch from "../../FormElements/Switch";
import Dropdown from "../../FormElements/DropDown/Dropown";
import TitleIcon from "../../Icons/TitleIcon";
import SubTitleIcon from "../../Icons/SubTitleIcon";
import TitleIcon2 from "../../Icons/TitleIcon2";
import DeleteIcon from "../../Icons/DeleteIcon";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearInfo, getSubCatInfo } from "../../../store/slice/InfoSlice";
import { getCat } from "../../../store/slice/CategorySlice";
import { createSub, getSub, updateSub } from "../../../store/slice/SubSlice";
import FAQs from "../../FAQs/FAQs";
import { clearFaq } from "../../../store/slice/FAQSlice";

// const schema = yup.object().shape({
//     firstName: yup.string().required("Name is required"),
//     lastName: yup.string().required("Name is required"),
//     phone: yup.string().required("Phone number is required"),
//     helpYou: yup.string().required("Tell us, how can we help you."),
//     email: yup
//         .string()
//         .email("Invalid email address")
//         .required("Email is required"),
// });

export default function SubModal({ submit }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      sub_category_slug: "",
      filter_heading: "",
      sub_heading: "",
      heading: ""
    }
  });

  const [categoryId, setCategoryId] = useState(-1);
  const [loading, setLoading] = useState(false)
  const [index, setIndex] = useState(-1)
  const { id } = useParams()
  const [slugError, setSlugError] = useState(false);
  const [subCat, cats] = useSelector((state) => [state.info.subCat, state.cat.Category])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const slug = watch("sub_category_slug")

  useEffect(() => {
    const timer = setTimeout(() => {
      if (slug.length === 0 || slug.match(/^[a-z0-9\-_]+$/)) {
        setSlugError(false)
      } else {
        setSlugError(true)
      }
    }
      , 300)

    return () => {
      clearTimeout(timer)
    }

  }, [slug])

  useEffect(() => {
    dispatch(getCat('order=active'))
    if (id) {
      dispatch(getSubCatInfo(id))
    }

    return () => {
      dispatch(clearFaq())
      dispatch(clearInfo("subCat"))
    }
  }, [])

  useEffect(() => {
    setValue("name", subCat.name)
    setValue("sub_category_slug", subCat.sub_category_slug)
    setValue("filter_heading", subCat.filter_heading)
    setValue("heading", subCat.heading)
    setValue("sub_heading", subCat.sub_heading)
    setCheckActive(subCat.active)
    if (id) {
      const index = cats.findIndex((item) => {
        return item.id === subCat?.category?.id
      })

      setCategoryId(index > -1 ? cats[index]?.id : -1)
      setIndex(index)
    }
  }, [subCat])

  const create = async (Data) => {
    if (categoryId < 0) {
      alert("select a category");
      return;
    }
    let submit
    setLoading(true)
    if (id) {
      const data = {
        ...Data,
        active: checkActive,
        id: id,
        categoryId,
      }
      if (Data.sub_category_slug === subCat.sub_category_slug) delete data.sub_category_slug
      submit = await dispatch(updateSub(data));
    } else {
      submit = await dispatch(createSub({ ...Data, categoryId }));
    }
    if (submit?.payload.type === 'success') {
      await dispatch(getSub('order=active'))
      navigate('/')
    }
    else {
      alert(submit?.payload?.message ?? "something went wrong")
    }
    setLoading(false)
    // submit()
    // console.log(formData);
    // submit({ id: subCat.id, formData })
  };

  const [checkActive, setCheckActive] = useState(subCat?.active ?? false);


  const handleStatusChange = (status) => {
    setCheckActive(status);
  }
  const [tab, setTab] = useState("page-details");
  const handelTabValue = (tabValue) => {
    if (tab != tabValue) {
      setTab(tabValue);
      return;
    }
  };

  return (
    <section className="bg-[#F7FBFF] pt-[82px] pb-7 md:pb-16">
      <div className="max-w-[1300px] w-full mx-auto px-5">
        <div>
          <h1 className="text-[#111111] font-bold text-[22px] mt-9 mb-6">
            Edit Sub Category
          </h1>
        </div>

        <div
          className="bg-white rounded-md mb-6"
          style={{
            boxShadow:
              "0px 0px 2px 0px rgba(18, 34, 59, 0.20), 0px 2px 14px 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <div className="px-5 py-8">
            <div>
              <ul className="flex gap-3">
                <li
                  className={`py-5 border-b-[3px] mr-3 ${tab == "page-details"
                    ? "border-[#1057CB]"
                    : "border-transparent"
                    }`}
                >
                  <button onClick={() => handelTabValue("page-details")}>
                    Page Details
                  </button>
                </li>
                {id && <li
                  className={`py-5 border-b-[3px] ${tab == "faq-details"
                    ? "border-[#1057CB]"
                    : "border-transparent"
                    }`}
                >
                  <button onClick={() => handelTabValue("faq-details")}>
                    FAQ Details
                  </button>
                </li>}
              </ul>
              <hr />
              <div className="md:pt-3">
                <div
                  className={` ${tab == "page-details" ? "block" : "hidden"}`}
                >
                  <form
                    onSubmit={handleSubmit(create)}
                    className="mt-3 md:mb-5"
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="w-full relative rounded-3xl bg-white"
                    >


                      {/* <input type='file' name='icon' {...register('icon')} onChange={(e) => { setIcon(e.target.files[0]) }} /> */}
                      <input type="submit" className="hidden" />
                      <div className="md:flex gap-6">
                        <div className="pb-7 w-full md:w-1/2">
                          <Input
                            type="text"
                            placeholder="Sub Category Name"
                            className="h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]"
                            register={register}
                            name="name"
                            label="Sub Category Name    "
                            requiredIcon="*"
                            labelClass="text-[#334055] font-semibold"
                            error={errors?.name}
                            inputBg="bg-transparent"
                          />
                        </div>
                        <div className="pb-7 w-full md:w-1/2">
                          <Input
                            type="text"
                            placeholder="Ex. app-development"
                            className="h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]"
                            register={register}
                            name="sub_category_slug"
                            label="Slug"
                            requiredIcon="*"
                            labelClass="text-[#334055] font-semibold"
                            error={errors?.slug}
                            inputBg="bg-transparent"
                          />
                          {slugError && <p className="text-red-500">no special character allowed</p>}
                        </div>
                      </div>

                      <div className="flex gap-3 md:flex-row flex-col">
                        <Dropdown
                          className={`pb-5 ${id ? "md:w-1/2 w-full" : "w-full"}`}
                          searchable={true}
                          buttonClass="h-[50px] !border-[#dcdcdc]"
                          click={(item) => {
                            setCategoryId(item.id);
                          }}
                          all={{}}
                          placeHolder={id ? cats[index]?.name : "Select a category"}
                          options={cats}
                        />

                        <div className={`pb-5 w-full md:w-1/2 ${id ? "block" : "hidden"}`}>
                          <div className="flex justify-between items-center">
                            <p class="pb-2 font-semibold">
                              Status <span class="text-[#DB3E3E]">*</span>
                            </p>
                            <div
                              className={`w-3 h-3 rounded-full ${checkActive ? "bg-[#09C26C]" : "bg-red-600"
                                }`}
                            ></div>
                          </div>
                          <Switch val={checkActive} change={handleStatusChange} title="Active/Inactive" />
                        </div>
                      </div>




                    </div>
                    <hr className="mt-3" />
                    <h2 className="my-5 text-base md:text-lg font-medium">
                      Page Details
                    </h2>
                    <label
                      htmlFor="title"
                      className="text-[#222222] text-base flex gap-2 font-medium items-center mb-3"
                    >
                      <TitleIcon /> Heading
                      <span className="text-[#EA0000] ">*</span>
                    </label>
                    <Input
                      type="text"
                      placeholder="Write here"
                      className="h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]"
                      register={register}
                      name="heading"
                      requiredIcon="*"
                      labelClass="text-[#334055] font-semibold"
                      error={errors?.name}
                      inputBg="bg-transparent"
                    />

                    <label
                      htmlFor="subTitle"
                      className="text-[#222222] text-base flex gap-2 font-medium items-center mb-3 mt-5"
                    >
                      <SubTitleIcon /> Sub Heading
                      <span className="text-[#EA0000] ">*</span>
                    </label>
                    <Input
                      type="text"
                      placeholder="Write here"
                      className="h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]"
                      register={register}
                      name="sub_heading"
                      requiredIcon="*"
                      labelClass="text-[#334055] font-semibold"
                      error={errors?.name}
                      inputBg="bg-transparent"
                    />

                    <label
                      htmlFor="title2"
                      className="text-[#222222] text-base flex gap-2 font-medium items-center mb-3 mt-5"
                    >
                      <TitleIcon2 /> Filter Heading
                      <span className="text-[#EA0000] ">*</span>
                    </label>
                    <Input
                      type="text"
                      placeholder="Write here"
                      className="h-[50px] text-[#454545] font-SF_Pro_Display lead-form-input !border-[#D2D2D2]"
                      register={register}
                      name="filter_heading"
                      requiredIcon="*"
                      labelClass="text-[#334055] font-semibold"
                      error={errors?.name}
                      inputBg="bg-transparent"
                    />
                    <div className="flex justify-between items-center mt-6">
                      <button
                        disabled={loading || slugError}
                        className={`text-white disabled:cursor-not-allowed font-semibold rounded-full border-0 bg-primary-green w-[136px] h-[40px] md:min-h-[40px] `}
                      >
                        Save
                      </button>
                      <Link to={'/'}>
                        <button
                          className={`text-[#323232] font-semibold rounded-full border-0 bg-[#EAEAEA] w-[136px] h-[40px] md:min-h-[40px] `}
                        >
                          Back
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
                {id && <div
                  className={` ${tab == "faq-details" ? "block" : "hidden"}`}>
                  <FAQs type={'subCategory'} id={id} />
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
